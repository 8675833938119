import React, { useRef } from "react";
import OriginalTextField from "@material-ui/core/TextField";
import { Container } from "./_styled";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

export const TextField = ({
    fullWidth = true,
    name = "unnamedTextField",
    variant = "outlined",
    required = false,
    value,
    type = "text",
    error = false,
    helperText = null,
    onChange,
    autoFocus = false,
    disabled = false,
    multiline = false,
    defaultHelperText,
    readOnly = false,
    startAdornment,
    rows = 1,
    size = "small",
    state,
    style,
    onSubmit,
    defaultValue = null,
    noBorder,
    isClearable = false,
    ...props
}) => {
    const textField = useRef();

    const handleClear = () => {
        if (onChange) onChange('', name);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && onSubmit) {
            onSubmit();
            textField.current.blur();
        }
    };

    const renderClearIcon = () =>
        isClearable && (value || (state && state[name])) ? (
            <InputAdornment position="end">
                <IconButton aria-label="clear input" onClick={handleClear}>
                    <ClearIcon />
                </IconButton>
            </InputAdornment>
        ) : null;

    /**
     * Return
     */
    return (
        <Container aria-label={"TextField" + name} {...{ noBorder }}>
            <OriginalTextField
                fullWidth={fullWidth}
                value={value !== undefined && value !== null ? value : state && state[name] !== undefined ? state[name] : ""}
                defaultValue={defaultValue}
                onChange={(e) => {
                    if (onChange) onChange(e.target.value, name);
                }}
                onKeyDown={onKeyDown}
                id={name}
                name={name}
                type={type}
                required={required}
                variant={variant}
                error={error}
                helperText={error ? helperText : defaultHelperText}
                autoFocus={autoFocus}
                disabled={disabled}
                multiline={multiline}
                InputProps={{
                    ...props.InputProps,
                    readOnly,
                    endAdornment: renderClearIcon(),
                    ...props.InputProps,
                }}
                inputRef={textField}
                rows={rows}
                size={size}
                {...props}
            />
        </Container>
    );
};
