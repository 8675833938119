import { userCookieName } from "V3";
import axios from "axios";
import {
    FORBIDDEN,
    ITEM_NOT_FOUND,
    SERVER_ERROR,
    UNAUTHORIZED,
    SUCCESS,
    FAIL,
    generalSuccessMessage,
    failErrorMessage,
    unauthorizedErrorMessage,
    itemNotFoundErrorMessage,
    METHOD_NOT_ALLOWED,
    NETWORK_ERROR,
    networkErrorMessage,
    serverErrorMessage,
} from "../constants/Constants";

function errorParser(err) {
    // console.log('Error in API call: ', err)
    console.log("Error response: ", err.response);
    if (err && (err.status === 401 || (err.response && err.response.status === 401))) {
        return {
            message: UNAUTHORIZED,
            status: err.status ? err.status : err.response.status,
            errorCode: err.response ? err.response.data.ErrorCode : -1,
            errors: err.response && err.response.data ? err.response.data.errors : [],
            responseData: err.response || -1,
        };
    } else if (err && (err.status === 404 || (err.response && err.response.status === 404))) {
        return {
            message: ITEM_NOT_FOUND,
            status: err.status ? err.status : err.response.status,
            errorCode: err.response ? err.response.data.ErrorCode : -1,
            errors: err.response && err.response.data ? err.response.data.errors : [],
            responseData: err.response || -1,
        };
    } else if (err && (err.status === 400 || (err.response && err.response.status === 400))) {
        return {
            message: FAIL,
            status: err.status ? err.status : err.response.status,
            errorCode: err.response ? err.response.data.ErrorCode : -1,
            errors: err.response && err.response.data ? err.response.data.errors : [],
            responseData: err.response || -1,
        };
    } else if (err && (err.status === 403 || (err.response && err.response.status === 403))) {
        return {
            message: FORBIDDEN,
            status: err.status ? err.status : err.response.status,
            errorCode: err.response ? err.response.data.ErrorCode : -1,
            errors: err.response && err.response.data ? err.response.data.errors : [],
            responseData: err.response || -1,
        };
    } else if (err && (err.status === 405 || (err.response && err.response.status === 405))) {
        return {
            message: METHOD_NOT_ALLOWED,
            status: err.status ? err.status : err.response.status,
            errorCode: err.response ? err.response.data.ErrorCode : -1,
            errors: err.response && err.response.data ? err.response.data.errors : [],
            responseData: err.response || -1,
        };
    } else if (err && (err.status === 500 || (err.response && err.response.status === 500))) {
        return {
            message: SERVER_ERROR,
            status: err.status ? err.status : err.response.status,
            errorCode: err.response ? err.response.data.ErrorCode : -1,
            errors: err.response && err.response.data ? err.response.data.errors : [],
            responseData: err.response || -1,
        };
    }
    return {
        message: NETWORK_ERROR,
        status: err.status,
        errorCode: err.response ? err.response.data.ErrorCode : -1,
        errors: err.response && err.response.data ? err.response.data.errors : [],
        responseData: err.response || -1,
    };
}

function responseParser(res) {
    if (res && res.data && (res.status === 200 || res.status === 201)) {
        return { data: res?.data?.data, status: res.status, message: SUCCESS, meta: res?.data?.meta };
    } else if (res && res.status === 401) {
        return { message: UNAUTHORIZED, status: res.status };
    } else if (res && res.status === 404) {
        return { message: ITEM_NOT_FOUND, status: res.status };
    } else if (res && res.status === 400) {
        return { message: res.message, status: res.status };
    } else if (res && res.status === 405) {
        return { message: res.message, status: res.status };
    } else if (res && res.status === 500) {
        return { message: SERVER_ERROR, status: res.status };
    }
    //Delete operation returns just 200 - 204 or 404
    return { message: res.status === 200 || res.status === 204 ? SUCCESS : FAIL, status: res.status };
}

function axiosConfig(method, endPoint, token, data, settings) {
    const {
        // promiseAll = false,
        streamFile = false,
    } = settings;
    const config = {
        method: method,
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            ...(endPoint === "image" && { "Content-Type": "multipart/form-data" }),
            ...(streamFile && { "Content-Type": "application/octet-stream" }),
        },
        url: `${process.env.REACT_APP_API_URL}${endPoint}`,
    };
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    } else {
        const currUser = JSON.parse(localStorage.getItem(userCookieName));
        const localToken = currUser?.token;
        config.headers["Authorization"] = `Bearer ${localToken}`;
    }
    if (data) {
        config.data = JSON.stringify(data);
    }
    return config;
}

export const getUserMessageByApiResponse = (response) => {
    if (response === SUCCESS) {
        return generalSuccessMessage;
    } else if (response === FAIL) {
        return failErrorMessage;
    } else if (response === UNAUTHORIZED) {
        return unauthorizedErrorMessage;
    } else if (response === ITEM_NOT_FOUND) {
        return itemNotFoundErrorMessage;
    } else if (response === NETWORK_ERROR) {
        return networkErrorMessage;
    } else {
        return serverErrorMessage;
    }
};

// Generic api call. Accepts method, url, auth token & params
export const apiCall = (method, url, token, params, settings = {}) => {
    const {
        promiseAll = false,
        // streamFile = false
    } = settings;
    const config = axiosConfig(method, url, token, params, settings);
    if (promiseAll) return axios(config);
    return axios(config)
        .then((res) => responseParser(res))
        .catch((err) => errorParser(err));
};
