import { userCookieName } from "V3";

const headers = (extra = null) => {
    const user = JSON.parse(localStorage.getItem(userCookieName));
    const { token } = user || {};
    const sub = (str) => (str || "").substring((str || "").length - 5);
    let stdHeaders = {
        "Content-Type": "application/json",
        // 'Access-Control-Allow-Headers': 'Authorization, Content-Type',
        Accept: "application/json",
        Authorization: "Bearer " + token,
    };
    let newHeaders = extra !== null ? { ...stdHeaders, ...extra } : stdHeaders;

    return {
        headers: newHeaders,
        token: sub(token),
    };
};

export default headers;
