import { _, catchError } from "V3";
import axios from "axios";
import headers from "../config/headers";
import moment from "moment";
import { metadataConstants } from "../store/constants/metadata";
import { metadataGlobalCorrections } from "../store/actions/metadata";
import { defaultUserTitle } from "../routes";

const clearOldLs = () => {
    localStorage.removeItem("dash_token_email");
    localStorage.removeItem("dash_token");
    localStorage.removeItem("dash_token_expiry");
    localStorage.removeItem("dashFx_apiResponses");
    localStorage.removeItem("user_avatar");
};
const clearISO = (vars) => {
    const { setUser, resetState, dispatch } = vars || {};
    localStorage.clear();
    setUser();
    resetState();
    dispatch({
        type: "SET_USER_PROFILE",
        data: {},
    });
    console.log("ls is cleared.");
};
const throwErrISO = (msg, vars, showError = true) => {
    const { setScreenText, openSnackBar } = vars || {};
    setScreenText((p) => [...p, msg]);
    if (showError) openSnackBar(msg, "error");
    console.log(msg);
    clearISO(vars);
};
const setUserShortISO = (data, vars) => {
    const { setUser, dispatch, userCookieName } = vars || {};
    setUser((c) => {
        const newObj = {
            ...c,
            ...data,
            admin: false,
            seo: false,
            publisher: false,
        };
        if (!newObj?.fePermissions?.grade) newObj.fePermissions.grade = defaultUserTitle;
        const currGrade = newObj?.fePermissions?.grade;
        if (currGrade === "Admin") newObj.admin = true;
        if (currGrade === "Publisher") newObj.publisher = true;
        if (currGrade === "Seo User") newObj.seo = true;
        dispatch({
            type: "SET_USER_PROFILE",
            data: newObj,
        });
        localStorage.setItem(userCookieName, JSON.stringify(newObj));
        return newObj;
    });
};
export const userCheckISO = async (vars) => {
    clearOldLs();
    let errorStatus = false;
    const {
        metadata: currMeta,
        userCookieName,
        setState,
        setInnerLoading,
        dispatch,
        setScreenText,
        openSnackBar,
    } = vars || {};
    console.log(currMeta);
    setInnerLoading(true);
    const pushScreenText = (str) => setScreenText((p) => [...p, str]);

    // -> Step0 Get metadata first
    let metadata;
    if (!currMeta || currMeta.length < 1 || Object.keys(currMeta).length < 1) {
        let getUrl = process.env.REACT_APP_API_URL + "metadata";
        pushScreenText("Metadata is requested...");
        await axios.get(getUrl, headers()).then(
            (result) => {
                const success = (data) => {
                    return {
                        type: metadataConstants.GET_METADATA_SUCCESS,
                        data,
                    };
                };
                dispatch(success(metadataGlobalCorrections(result?.data)));
                metadata = result?.data;
                pushScreenText("✓ Successful.");
            },
            (res) => {
                throwErrISO("Metadata " + catchError({ res }), vars);
                errorStatus = true;
            },
        );
    } else {
        metadata = currMeta;
    }

    if (errorStatus) return;

    // -> Step1 Check if there is a cookie or not.
    const currUser = JSON.parse(localStorage.getItem(userCookieName));
    pushScreenText("Looking for user cookies...");
    if (!currUser?.token) {
        throwErrISO("There isn't any cookies. Forwarding user to the login screen...", vars, false);
        errorStatus = true;
        setInnerLoading(false);
    } else pushScreenText("✓ Successful.");

    // -> Step2 Check expireAt
    if (errorStatus) return;
    const { expireAt } = currUser || {};
    pushScreenText("Checking the validity period of the token...");
    if (!expireAt || parseInt(expireAt) < moment().unix()) {
        throwErrISO("Failed. Expired token. Forwarding user to the login screen...", vars, false);
        errorStatus = true;
        setInnerLoading(false);
    } else pushScreenText("✓ Successful.");

    // -> Step3 Check existing cookie is valid or not.
    if (errorStatus) return;
    pushScreenText("Checking the validity of the token...");
    const url = process.env.REACT_APP_API_ADMIN_URL + "check";
    await axios.get(url, headers()).then(
        (apiResponse) => {
            pushScreenText("✓ Successful.");
        },
        (error) => {
            throwErrISO("Failed. Invalid token. Forwarding user to the login screen...", vars, false);
            openSnackBar("Your account has been deactivated. Please contact administrator.", "error");
            errorStatus = true;
            setInnerLoading(false);
        },
    );

    // -> Step4 Get Users Profile
    if (errorStatus) return;
    pushScreenText("User profile requested...");
    const url2 = process.env.REACT_APP_API_URL + "me";
    let meResponse;
    await axios.get(url2, headers()).then(
        (result) => {
            pushScreenText("✓ Successful.");
            meResponse = result.data.data;
        },
        (res) => {
            throwErrISO("me " + catchError({ res }), vars);
            errorStatus = true;
        },
    );

    /**
     * Step5 Get User Settings
     */
    if (errorStatus) return;
    const url3 = process.env.REACT_APP_API_URL + "user_settings_by_token";
    let userSettings;
    pushScreenText("User settings requested...");
    await axios.get(url3, headers()).then(
        (result) => {
            pushScreenText("✓ Successful.");
            const incomingData = result?.data?.data || result?.data || [];
            let us = {};
            _.forEach(incomingData, (item) => {
                if (item.settingId === 1 && item.settingValue === "Array") return;
                let setting = metadata.data.settings?.filter((setting) => setting?.id === item.settingId);
                let stub = "custom_campaign_reports"; // setting[0].stub;
                us[stub] = setting?.type === "bool" ? parseInt(item.settingValue) : item.settingValue;
            });
            setState((state) => {
                return { ...state, userSettings: us };
            });
            userSettings = us;
        },
        (res) => {
            throwErrISO("user_settings_by_token " + catchError({ res }), vars);
            errorStatus = true;
        },
    );

    /**
     * Last Step - Write all data
     */
    if (errorStatus) return;
    const newUser = {
        ...currUser,
        ...meResponse,
        userSettings,
    };
    console.clear();
    pushScreenText("✓✓✓ User check is successful. All went fine.");
    console.log("✓✓✓ User check is successful.", currUser?.email, metadata);
    setUserShortISO(newUser, vars);
};

export const googleSuccessISO = (resp, vars) => {
    const { userCookieName } = vars || {};

    //new resp
    // {
    //     "access_token": "ya29.a0AWY7CklP-Bq0hsibndYPcDP3PDDa4017asuf4QSDJnUXu-V1DXsUBus5KcNGDIcT_aAptHwBsyrg_hfd3T4pZkMR2oWFHjZxUX0lYHF--pBx-IsdQULG5gJHgDabbxpjXHgnxnXBKhZHX5-WAQA-riqVImRQjXkaCgYKAZoSARESFQG1tDrpgT-bkdpeNExUlsXZKDW3Ng0166",
    //     "token_type": "Bearer",
    //     "expires_in": 3599,
    //     "scope": "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
    //     "authuser": "2",
    //     "hd": "finixio.com",
    //     "prompt": "none"
    // }

    //old resp
    // {
    //     "Ba": "102455449477205881014",
    //     "zc": {
    //         "token_type": "Bearer",
    //         "access_token": "ya29.a0AWY7Cknh4F9Uk_NY2Ukpf3UJ1I2JDd4xcnXIdPm1t7_XCGkSCCVJWMGskoTuZG-Fj-oDoDUhuNxZP8ymU08QJ-2HCSW1v-oOulQR3iYkIPmfx8DrHYOM2flxWUnZZIzuUq_EsmPKQZEPPS5AaDyJ55KU8ym7f1gaCgYKASESARESFQG1tDrpL2F24_Lj1cCtnywePybQ0w0166",
    //         "scope": "email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email",
    //         "login_hint": "AJDLj6JUa8yxXrhHdWRHIV0S13cAa8rHw2ePMHj3LJYY4v232UkkEkFzRWXiqKKtqoxLnqv4_URxv1A3NoMqMR0G2irHIYJIwQ",
    //         "expires_in": 3599,
    //         "id_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImM5YWZkYTM2ODJlYmYwOWViMzA1NWMxYzRiZDM5Yjc1MWZiZjgxOTUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjg2OTU2NDEzOTQ1LTB1Z3FiYmRwdGpkaTN1YmRiaDdpaTV1cWUwcXRxN3ZzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjg2OTU2NDEzOTQ1LTB1Z3FiYmRwdGpkaTN1YmRiaDdpaTV1cWUwcXRxN3ZzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAyNDU1NDQ5NDc3MjA1ODgxMDE0IiwiaGQiOiJmaW5peGlvLmNvbSIsImVtYWlsIjoiYmFyaXMua3VyYW5AZmluaXhpby5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6ImZuWU9TWGR1TVFFM1NUNjdkMEFuaGciLCJuYW1lIjoiQmFyaXMgS3VyYW4iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUdObXl4YkZUSVd1QlNEMTdiVERQeGZNRWpHTFlyVmZRamFJRldET3F5TlE9czk2LWMiLCJnaXZlbl9uYW1lIjoiQmFyaXMiLCJmYW1pbHlfbmFtZSI6Ikt1cmFuIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE2ODMxMTIxODIsImV4cCI6MTY4MzExNTc4MiwianRpIjoiMGE2OGQyNWI3NGU2MWRhNmM3MTM0MGRmNjNjMzdkZjcxYzlmMzI5MSJ9.Vv3ADR10B2iBQNJVsbiJmFiS1WSRuYCunKM5ZO6j4xPAw4JRGfKGUYOemQ3sCPCE9Fka15MC-rY0KpSUTZC2FLhvLhj6Nf40pwKts1OhfXmx4DGUfFJNvwhQpsw8aRRJsA1d90Iruu-9vHXpzzHzHgDVP9EFi28siVm9prodLZKMB4NweIFRaEQoHTqi0J1i8zRML5EjS68tT4N5EnmMVVBp8FJhvZji7yQh8bi5b7c4J-yAyS48x93iA10Or4qyz2EZjeOQhZtAdUNXAN8WUq7bUiNBC72sAlthF8J6G9Cs4GTuBnZ94JguISwsvWrz__S5RGgSDvpF7LVe-eTt6Q",
    //         "session_state": {
    //             "extraQueryParams": {
    //                 "authuser": "2"
    //             }
    //         },
    //         "first_issued_at": 1683112182928,
    //         "expires_at": 1683115781928,
    //         "idpId": "google"
    //     },
    //     "wv": {
    //         "IY": "102455449477205881014",
    //         "zf": "Baris Kuran",
    //         "ZZ": "Baris",
    //         "pY": "Kuran",
    //         "QO": "https://lh3.googleusercontent.com/a/AGNmyxbFTIWuBSD17bTDPxfMEjGLYrVfQjaIFWDOqyNQ=s96-c",
    //         "iw": "baris.kuran@finixio.com"
    //     },
    //     "googleId": "102455449477205881014",
    //     "tokenObj": {
    //         "token_type": "Bearer",
    //         "access_token": "ya29.a0AWY7Cknh4F9Uk_NY2Ukpf3UJ1I2JDd4xcnXIdPm1t7_XCGkSCCVJWMGskoTuZG-Fj-oDoDUhuNxZP8ymU08QJ-2HCSW1v-oOulQR3iYkIPmfx8DrHYOM2flxWUnZZIzuUq_EsmPKQZEPPS5AaDyJ55KU8ym7f1gaCgYKASESARESFQG1tDrpL2F24_Lj1cCtnywePybQ0w0166",
    //         "scope": "email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email",
    //         "login_hint": "AJDLj6JUa8yxXrhHdWRHIV0S13cAa8rHw2ePMHj3LJYY4v232UkkEkFzRWXiqKKtqoxLnqv4_URxv1A3NoMqMR0G2irHIYJIwQ",
    //         "expires_in": 3599,
    //         "id_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImM5YWZkYTM2ODJlYmYwOWViMzA1NWMxYzRiZDM5Yjc1MWZiZjgxOTUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjg2OTU2NDEzOTQ1LTB1Z3FiYmRwdGpkaTN1YmRiaDdpaTV1cWUwcXRxN3ZzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjg2OTU2NDEzOTQ1LTB1Z3FiYmRwdGpkaTN1YmRiaDdpaTV1cWUwcXRxN3ZzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAyNDU1NDQ5NDc3MjA1ODgxMDE0IiwiaGQiOiJmaW5peGlvLmNvbSIsImVtYWlsIjoiYmFyaXMua3VyYW5AZmluaXhpby5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6ImZuWU9TWGR1TVFFM1NUNjdkMEFuaGciLCJuYW1lIjoiQmFyaXMgS3VyYW4iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUdObXl4YkZUSVd1QlNEMTdiVERQeGZNRWpHTFlyVmZRamFJRldET3F5TlE9czk2LWMiLCJnaXZlbl9uYW1lIjoiQmFyaXMiLCJmYW1pbHlfbmFtZSI6Ikt1cmFuIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE2ODMxMTIxODIsImV4cCI6MTY4MzExNTc4MiwianRpIjoiMGE2OGQyNWI3NGU2MWRhNmM3MTM0MGRmNjNjMzdkZjcxYzlmMzI5MSJ9.Vv3ADR10B2iBQNJVsbiJmFiS1WSRuYCunKM5ZO6j4xPAw4JRGfKGUYOemQ3sCPCE9Fka15MC-rY0KpSUTZC2FLhvLhj6Nf40pwKts1OhfXmx4DGUfFJNvwhQpsw8aRRJsA1d90Iruu-9vHXpzzHzHgDVP9EFi28siVm9prodLZKMB4NweIFRaEQoHTqi0J1i8zRML5EjS68tT4N5EnmMVVBp8FJhvZji7yQh8bi5b7c4J-yAyS48x93iA10Or4qyz2EZjeOQhZtAdUNXAN8WUq7bUiNBC72sAlthF8J6G9Cs4GTuBnZ94JguISwsvWrz__S5RGgSDvpF7LVe-eTt6Q",
    //         "session_state": {
    //             "extraQueryParams": {
    //                 "authuser": "2"
    //             }
    //         },
    //         "first_issued_at": 1683112182928,
    //         "expires_at": 1683115781928,
    //         "idpId": "google"
    //     },
    //     "tokenId": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImM5YWZkYTM2ODJlYmYwOWViMzA1NWMxYzRiZDM5Yjc1MWZiZjgxOTUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjg2OTU2NDEzOTQ1LTB1Z3FiYmRwdGpkaTN1YmRiaDdpaTV1cWUwcXRxN3ZzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjg2OTU2NDEzOTQ1LTB1Z3FiYmRwdGpkaTN1YmRiaDdpaTV1cWUwcXRxN3ZzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAyNDU1NDQ5NDc3MjA1ODgxMDE0IiwiaGQiOiJmaW5peGlvLmNvbSIsImVtYWlsIjoiYmFyaXMua3VyYW5AZmluaXhpby5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6ImZuWU9TWGR1TVFFM1NUNjdkMEFuaGciLCJuYW1lIjoiQmFyaXMgS3VyYW4iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUdObXl4YkZUSVd1QlNEMTdiVERQeGZNRWpHTFlyVmZRamFJRldET3F5TlE9czk2LWMiLCJnaXZlbl9uYW1lIjoiQmFyaXMiLCJmYW1pbHlfbmFtZSI6Ikt1cmFuIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE2ODMxMTIxODIsImV4cCI6MTY4MzExNTc4MiwianRpIjoiMGE2OGQyNWI3NGU2MWRhNmM3MTM0MGRmNjNjMzdkZjcxYzlmMzI5MSJ9.Vv3ADR10B2iBQNJVsbiJmFiS1WSRuYCunKM5ZO6j4xPAw4JRGfKGUYOemQ3sCPCE9Fka15MC-rY0KpSUTZC2FLhvLhj6Nf40pwKts1OhfXmx4DGUfFJNvwhQpsw8aRRJsA1d90Iruu-9vHXpzzHzHgDVP9EFi28siVm9prodLZKMB4NweIFRaEQoHTqi0J1i8zRML5EjS68tT4N5EnmMVVBp8FJhvZji7yQh8bi5b7c4J-yAyS48x93iA10Or4qyz2EZjeOQhZtAdUNXAN8WUq7bUiNBC72sAlthF8J6G9Cs4GTuBnZ94JguISwsvWrz__S5RGgSDvpF7LVe-eTt6Q",
    //     "accessToken": "ya29.a0AWY7Cknh4F9Uk_NY2Ukpf3UJ1I2JDd4xcnXIdPm1t7_XCGkSCCVJWMGskoTuZG-Fj-oDoDUhuNxZP8ymU08QJ-2HCSW1v-oOulQR3iYkIPmfx8DrHYOM2flxWUnZZIzuUq_EsmPKQZEPPS5AaDyJ55KU8ym7f1gaCgYKASESARESFQG1tDrpL2F24_Lj1cCtnywePybQ0w0166",
    //     "profileObj": {
    //         "googleId": "102455449477205881014",
    //         "imageUrl": "https://lh3.googleusercontent.com/a/AGNmyxbFTIWuBSD17bTDPxfMEjGLYrVfQjaIFWDOqyNQ=s96-c",
    //         "email": "baris.kuran@finixio.com",
    //         "name": "Baris Kuran",
    //         "givenName": "Baris",
    //         "familyName": "Kuran"
    //     }
    // }

    let postUrl = process.env.REACT_APP_API_AUTH_URL + "verify_token";
    axios.post(postUrl, resp, headers()).then(
        (apiResponse) => {
            if (apiResponse?.status !== 200 || apiResponse?.data?.statusCode !== 200) return;
            const data = apiResponse?.data?.data?.data;
            const newUserObj = {
                email: data?.email,
                token: data?.jwt,
                expireAt: data?.expireAt,
            };
            localStorage.setItem(userCookieName, JSON.stringify(newUserObj));
            userCheckISO(vars);
        },
        (res) => {
            throwErrISO("verify_token " + catchError({ res }), vars);
        },
    );
};
export const googleFailsISO = (resp, vars) => throwErrISO("Google login failure:", vars);
